// header speciality classes

function checkIfScrolledDown() {
	const header = document.querySelector('header')
	if ( !header ) return
	if ( 0 < window.scrollY ) {
		header.classList.add('is-scrolled-down')
	} else {
		header.classList.remove('is-scrolled-down')
	}
}

document.addEventListener( 'DOMContentLoaded', checkIfScrolledDown )
window.addEventListener( 'scroll', checkIfScrolledDown )
