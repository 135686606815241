/**
 * asynchronously load gsap with defaults and auto-registered optional plugins
 */

import gsapDefaults from '../../data/gsap-defaults'

export default (plugins = []) => new Promise(
	(resolve, reject) => {
		Promise.all([
			import('gsap'),
			...(0 <= plugins.indexOf('CSSRulePlugin') ? [import('gsap/CSSRulePlugin')] : []),
			...(0 <= plugins.indexOf('Draggable') ? [import('gsap/Draggable')] : []),
			...(0 <= plugins.indexOf('EaselPlugin') ? [import('gsap/EaselPlugin')] : []),
			...(0 <= plugins.indexOf('EasePack') ? [import('gsap/EasePack')] : []),
			...(0 <= plugins.indexOf('MotionPathPlugin') ? [import('gsap/MotionPathPlugin')] : []),
			...(0 <= plugins.indexOf('PixiPlugin') ? [import('gsap/PixiPlugin')] : []),
			...(0 <= plugins.indexOf('ScrollToPlugin') ? [import('gsap/ScrollToPlugin')] : []),
			...(0 <= plugins.indexOf('TextPlugin') ? [import('gsap/TextPlugin')] : []),
		])
			.catch(error => reject(error))
			.then(([{default: gsap}, ...modules]) => {
				modules.map(mod => gsap.registerPlugin(mod.default))
				gsap.defaults(gsapDefaults)
				resolve(gsap)
			})
	}
)
