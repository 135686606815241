/**
 * check if DOM nodes are currenctly in the viewport
 */

export default inViewport

/**
 * when an Element is passed in: check if it is in the viewport
 * when a NodeList or Array is passed in: return an array of elements that are
 * in the viewport
 *
 * @param {Element|NodeList|Array} node
 * @param {number} offsetX
 * @param {number} offsetY
 */
export function inViewport(node, offsetX = 0, offsetY = 0) {
	let inView = false
	if (node instanceof NodeList || 'object' === typeof node) {
		inView = nodeListInViewport(node, offsetX, offsetY)
	} else {
		inView = elementInViewport(node, offsetX, offsetY)
	}
	return inView
}

/**
 * when an Element is passed in: check if it is not in the viewport
 * when a NodeList or Array is passed in: return an array of elements that are
 * not in the viewport
 *
 * @param {Element|NodeList|Array} node
 * @param {number} offsetX
 * @param {number} offsetY
 */
export function notInViewport(node, offsetX = 0, offsetY = 0) {
    let inview = false
    if (node instanceof NodeList || 'object' === typeof node) {
        inview = nodeListNotInViewport(node, offsetX, offsetY)
    } else {
        inview = ! elementInViewport(node, offsetX, offsetY)
    }
    return inview
}

/**
 * check if an element is in the viewport
 *
 * @param {Element} node
 * @param {number} offsetX
 * @param {number} offsetY
 */
export function elementInViewport(node, offsetX = 0, offsetY = 0) {
    const winr = window.innerWidth
    const wdoc = document.documentElement.clientWidth
    const wbod = document.body.clientWidth
    const width = winr || wdoc || wbod
    const hinr = window.innerHeight
    const hdoc = document.documentElement.clientHeight
    const hbod = document.body.clientHeight
    const height = hinr || hdoc || hbod
    const rect = node.getBoundingClientRect()
    const params = {
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
        width: rect.width,
        height: rect.height
    }
    if (0 !== offsetY) {
        if ('string' === typeof offsetY && 0 < offsetY.indexOf('%')) {
            offsetY = parseInt(
                params.height *
                parseFloat(offsetY.replace(/[^0-9\.]+/ig, '')) / 100
           )
        }
        if ('string' === typeof offsetY) {
            offsetY = parseInt(offsetY.replace(/[^0-9\.]+/ig, ''))
        }
        params.top = params.top + offsetY
        params.bottom = params.bottom + offsetY
    }
    if (0 !== offsetX) {
        if ('string' === typeof offsetX && 0 < offsetX.indexOf('%')) {
            offsetX = parseInt(
                params.width *
                parseFloat(offsetX.replace(/[^0-9\.\-]+/ig, '')) / 100
           )
        }
        if ('string' === typeof offsetX) {
            offsetX = parseInt(offsetX.replace(/[^0-9\.]+/ig, ''))
        }
        params.left = params.left + offsetX
        params.right = params.right + offsetX
    }
    return (
        0 < params.bottom &&
        0 < params.right &&
        params.left < width &&
        params.top < height
   )
}

/**
 * filter a NodeList or Array of Elements to return only the Elements that are
 * in the viewport
 *
 * @param {NodeList|Array} nodes
 * @param {number} offsetX
 * @param {number} offsetY
 */
export function nodeListInViewport(nodes, offsetX = 0, offsetY = 0) {
    const list = []
    nodes.forEach(node => {
        if (elementInViewport(node, offsetX, offsetY)) {
            list.push(node)
		}
		return node
    })
    return list
}

/**
 * filter a NodeList or Array of Elements to return only the Elements that are
 * not in the viewport
 *
 * @param {NodeList|Array} nodes
 * @param {number} offsetX
 * @param {number} offsetY
 */
export function nodeListNotInViewport(nodes, offsetX = 0, offsetY = 0) {
    const list = []
    nodes.forEach(node => {
        if (! elementInViewport(node, offsetX, offsetY)) {
            list.push(node)
        }
		return node
    })
    return list
}
