import loadGSAP from '../../../../component/load-gsap'

document.addEventListener(
	'DOMContentLoaded',
	() => {
		if (!document.querySelector('.skip-link')) return
		loadGSAP(['ScrollToPlugin']).then(gsap => {
			document.addEventListener('click', event => {
				if (!event.target.matches('.skip-link')) return
				event.preventDefault()
				event.stopPropagation()
				const scrollTo = event.target.getAttribute('href')
				if (!scrollTo.match(/\#[a-z0-9_-]+/i)) return
				gsap.to(window, { scrollTo }).then(() => event.target.blur())
			})
		})
	}
)
