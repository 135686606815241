import countUp from '../../component/count-up'
import { inViewport } from '../../component/in-viewport'

function checkIfInViewport() {
	const elements = document.querySelectorAll('.wp-block-blob-count-up__number:not(.is-counted-up)')
	if (!elements) return
	const elementsInViewport = inViewport(elements)
	if (!elementsInViewport) return
	elementsInViewport.forEach(element => {
		countUp(element, 0, parseInt(element.getAttribute('data-number')))
		element.classList.add('is-counted-up')
	})
}

document.addEventListener('DOMContentLoaded', checkIfInViewport)
document.addEventListener('scroll', checkIfInViewport)
