import loadGSAP from '../../../../component/load-gsap'

document.addEventListener(
	'DOMContentLoaded',
	() => {
		if (!document.querySelector('header .main-navigation')) return
		document.addEventListener('click', event => {
			if (!event.target.matches(' header .main-navigation__toggle')) return
			event.stopPropagation()
			event.preventDefault()
			const header = document.querySelector('header')
			header.classList.toggle('has-main-navigation-open')
			const menu = event.target.parentNode.querySelector('.main-navigation__menu')
			if (menu && 782 > window.innerWidth) {
				loadGSAP().then(gsap => {
					if (header.classList.contains('has-main-navigation-open')) {
						menu.style.height = 0
						gsap.to(menu, { height: 'auto' }).then(() => menu.style = {})
					} else {
						menu.style.display = 'block'
						gsap.to(menu, { height: 0, paddingTop: 0, paddingBottom: 0 }).then(() => menu.style = {})
					}
				})
			}
		})
	}
)
